import { AddToCartArgs, CustomerType, RemoveFromCartArgs, UpdateCartItemQuantityArgs } from 'TYPES/api.body';
import LOCALSTORAGE_KEYS from 'constants/localStorage';
import QUERY_KEYS from 'network/config/queryKeys';
import { enqueueSnackbar } from 'notistack';
import { QueryClient, UseMutateAsyncFunction } from 'react-query';
import { CartItem } from 'store/atoms/cartAtom';

const handleAddMutateError = (err: any) => {
  if (err?.response?.data?.message) {
    enqueueSnackbar(err?.response?.data?.message, {
      variant: 'error'
    });
  } else {
    enqueueSnackbar('Failed to update product quantity', {
      variant: 'error'
    });
  }
  console.log(err);
};

export const syncLocalAndServerCart = (
  serverCart: CartItem[],
  addMutate: UseMutateAsyncFunction<any, unknown, AddToCartArgs, unknown>,
  updateMutate: UseMutateAsyncFunction<any, unknown, UpdateCartItemQuantityArgs, unknown>,
  removeMutate: UseMutateAsyncFunction<any, unknown, RemoveFromCartArgs, unknown>,
  setCartList: (valOrUpdater: CartItem[] | ((currVal: CartItem[]) => CartItem[])) => void,
  customerType?: CustomerType
) => {
  const localCartStr = localStorage.getItem(LOCALSTORAGE_KEYS.CART);
  if (localCartStr !== null) {
    let cart = [...serverCart];

    if (customerType !== 'BUSINESS') {
      const serverIds = serverCart.map(item => item.id);
      const localCart: CartItem[] = JSON.parse(localCartStr);
      const itemsToAdd: CartItem[] = [];
      localCart.forEach(item => {
        if (serverIds.includes(item.id)) {
          const toUpdateIndex = cart.findIndex(i => item.id === i.id);
          cart = [
            ...cart.slice(0, toUpdateIndex),
            { ...cart[toUpdateIndex], quantity: item.quantity },
            ...cart.slice(toUpdateIndex + 1)
          ];
          setCartList(cart);
          performUpdateCartItemQty(serverCart, item.quantity, item, updateMutate, removeMutate, setCartList);
        } else {
          itemsToAdd.push(item);
        }
      });
      if (itemsToAdd.length > 0) {
        setCartList([...cart, ...itemsToAdd]);
        addMutate({
          items: itemsToAdd.map(item => ({
            product_id: item.id,
            quantity: item.quantity
          })),
          customerType
        });
      }
    }
    localStorage.removeItem(LOCALSTORAGE_KEYS.CART);
  }
};

export const localAddProductToCart = (
  qty: number,
  cart: CartItem[],
  product: CartItem,
  setCartList: (valOrUpdater: CartItem[] | ((currVal: CartItem[]) => CartItem[])) => void
) => {
  const existing = cart.find(item => item.id === product.id);
  let newCart: CartItem[] = [];
  if (existing) {
    newCart = cart.map(item => (item.id === product.id ? { ...item, quantity: item.quantity + qty } : item));
  } else {
    newCart = [...cart, product];
  }
  localStorage.setItem(LOCALSTORAGE_KEYS.CART, JSON.stringify(newCart));
  setCartList(newCart);
};

export const localRemoveProductFromCart = (
  cart: CartItem[],
  index: number,
  setCartList: (valOrUpdater: CartItem[] | ((currVal: CartItem[]) => CartItem[])) => void
) => {
  const items = [...cart];
  items.splice(index, 1);
  localStorage.setItem(LOCALSTORAGE_KEYS.CART, JSON.stringify(items));
  setCartList(items);
};

export const localUpdateCartItemQuantity = (
  newQty: number,
  cart: CartItem[],
  product: CartItem,
  setCartList: (valOrUpdater: CartItem[] | ((currVal: CartItem[]) => CartItem[])) => void
) => {
  let newCart: CartItem[] = [...cart];
  const index = cart.findIndex(item => item.id === product.id);
  if (newQty === 0) {
    newCart = newCart.filter(item => item.id !== product.id);
    localStorage.setItem(LOCALSTORAGE_KEYS.CART, JSON.stringify(newCart));
    setCartList(newCart);
  } else if (newQty === 1 && index < 0) {
    newCart = [...cart, { ...product, quantity: 1 }];
    localStorage.setItem(LOCALSTORAGE_KEYS.CART, JSON.stringify(newCart));
    setCartList(newCart);
    enqueueSnackbar(`${product.name} added to cart`, { variant: 'success' });
  } else {
    if (index >= 0) {
      newCart[index] = { ...newCart[index], quantity: newQty };
    }
    localStorage.setItem(LOCALSTORAGE_KEYS.CART, JSON.stringify(newCart));
    setCartList(newCart);
  }
};

export const performAddProductToCart = async (
  cart: CartItem[],
  products: CartItem[],
  addMutate: UseMutateAsyncFunction<any, unknown, AddToCartArgs, unknown>,
  updateMutate: UseMutateAsyncFunction<any, unknown, UpdateCartItemQuantityArgs, unknown>,
  removeMutate: UseMutateAsyncFunction<any, unknown, RemoveFromCartArgs, unknown>,
  setCartList: (valOrUpdater: CartItem[] | ((currVal: CartItem[]) => CartItem[])) => void,
  queryClient: QueryClient,
  customerType?: CustomerType
) => {
  const currentCartIds = cart.map(item => item.id);
  const existingItems = products.filter(item => currentCartIds.includes(item.id));
  const newItems = products.filter(item => !currentCartIds.includes(item.id));
  const promises: Promise<void>[] = [];
  if (existingItems.length > 0) {
    for (const i of existingItems) {
      promises.push(
        (async () =>
          await performUpdateCartItemQty(
            cart,
            i.quantity + cart.find(item => item.id === i.id)!.quantity,
            i,
            updateMutate,
            removeMutate,
            setCartList,
            customerType
          ))()
      );
    }
    Promise.all(promises)
      .then(() => {
        queryClient.invalidateQueries(QUERY_KEYS.CART);
        enqueueSnackbar(`Updated ${existingItems.length} product quantities`, {
          variant: 'success'
        });
      })
      .catch(err => {
        handleAddMutateError(err);
      });
  }
  if (newItems.length > 0) {
    addMutate({
      items: newItems.map(item => ({
        product_id: item.id,
        quantity: item.quantity
      })),
      customerType
    })
      .then(data => {
        if (data?.status === 'success') {
          queryClient.invalidateQueries(QUERY_KEYS.CART);
          setCartList([...cart, ...newItems]);
          if (newItems.length === 1) {
            enqueueSnackbar(`${newItems[0].name} added to cart`, {
              variant: 'success'
            });
          } else {
            enqueueSnackbar(`${newItems.length} products added to cart`, {
              variant: 'success'
            });
          }
        } else {
          enqueueSnackbar(data?.description, { variant: 'error' });
        }
      })
      .catch(err => handleAddMutateError(err));
  }
};

export const performRemoveProductFromCart = async (
  cart: CartItem[],
  index: number,
  removeMutate: UseMutateAsyncFunction<any, unknown, RemoveFromCartArgs, unknown>,
  setCartList: (valOrUpdater: CartItem[] | ((currVal: CartItem[]) => CartItem[])) => void,
  queryClient: QueryClient,
  customerType?: CustomerType
) => {
  const item = cart[index];

  removeMutate({ id: item.id, customerType })
    .then(() => {
      setCartList([...cart.slice(0, index), ...cart.slice(index + 1)]);
      queryClient.invalidateQueries(QUERY_KEYS.CART);
      enqueueSnackbar(`${item.name} removed from cart`, { variant: 'success' });
    })
    .catch(err => {
      enqueueSnackbar('Failed to remove item from cart', { variant: 'error' });
      console.log(err);
    });
};

export const performUpdateCartItemQty = async (
  cart: CartItem[],
  newQty: number,
  product: CartItem,
  updateMutate: UseMutateAsyncFunction<any, unknown, UpdateCartItemQuantityArgs, unknown>,
  removeMutate: UseMutateAsyncFunction<any, unknown, RemoveFromCartArgs, unknown>,
  setCartList: (valOrUpdater: CartItem[] | ((currVal: CartItem[]) => CartItem[])) => void,
  customerType?: CustomerType
) => {
  if (newQty < 1) {
    setCartList(c => c.filter(item => item.id !== product.id));
    removeMutate({ id: product.id, customerType }).catch(err => console.log(err));
  } else {
    const cartId = cart.find(item => item.id === product.id)?.cartId;
    updateMutate({ cartId: cartId ?? product.id, qty: newQty })
      .then(res => {
        console.log({ res });
        setCartList(cart.map(item => (item.id === product.id ? { ...item, quantity: newQty } : item)));
      })
      .catch(err => console.log(err));
  }
};
