import { FiMinus, FiPlus } from 'react-icons/fi';

type IProductQtyCounter = {
  value: number;
  setValue: (arr: number, operation?: 'increment' | 'decrement') => void;
  customerType?: 'REGURAR' | 'BUSINESS';
  max?: number;
};

export const ProductQtyCounter = ({ value, setValue, customerType, max }: IProductQtyCounter) => {
  const DisableIncrementButton: boolean = max === undefined ? false : max < 1 ? true : false;

  const handleIncrement = () => {
    if (max === 0) {
      console.log(`Max capacity ${max} reached, value  ${value} is not updated.`);
      return;
    }

    setValue(value + 1, 'increment');
  };

  const handleDecrement = () => {
    if (value > 0) {
      setValue(value - 1, 'decrement');
    }
  };

  return (
    <div className="flex items-center gap-2">
      <button
        className="outline-none text-sm p-1 rounded-full aspect-square border border-black/30"
        onClick={() => {
          handleDecrement();
        }}>
        <FiMinus />
      </button>
      <input
        data-business={customerType === 'BUSINESS'}
        min={1}
        step="any"
        type="number"
        disabled={customerType !== 'BUSINESS'}
        value={value}
        onChange={e => setValue(Number(e.currentTarget.value))}
        className="bg-transparent data-[business=true]:border border-black/10 hover:border-black/50 transition-all rounded py-1 w-12 text-center font-light text-sm outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
      />
      <button
        disabled={DisableIncrementButton}
        className={`outline-none text-sm p-1 rounded-full aspect-square border border-black/30 ${
          DisableIncrementButton && ' opacity-30'
        }`}
        onClick={() => {
          handleIncrement();
        }}>
        <FiPlus />
      </button>
    </div>
  );
};
