import { CircularProgress, Tooltip } from '@mui/material';
import { DeleteCartProduct } from 'assets/Icons';
import $ from 'lodash';
import QUERY_KEYS from 'network/config/queryKeys';
import { removeProductFromCart, updateCartItemQty } from 'network/mutations/products';
import { enqueueSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { CartItem, cartListAtom } from 'store/atoms/cartAtom';
import { profileAtom } from 'store/atoms/profileAtom';
import { formatCurrency } from 'utils/currencyFormater';
import { truncateCharCount } from 'utils/truncate';

import { Logo, ProductQtyCounter } from '../../atoms';
import {
  localRemoveProductFromCart,
  localUpdateCartItemQuantity,
  performRemoveProductFromCart,
  performUpdateCartItemQty
} from '../Goods/utils';
import { CartProductCon } from './style';

type ICartProp = {
  item: CartItem;
  index: number;
};

export const CartProduct: React.FC<ICartProp> = ({ item, index }) => {
  const queryClient = useQueryClient();
  const profile = useRecoilValue(profileAtom);
  const { amount, name, photo, quantityAvailable, quantity } = item;

  const [value, setValue] = useState(quantity);
  const prevValueRef = useRef(quantity);

  const { mutateAsync: removeFn, isLoading: deleting } = useMutation(removeProductFromCart);
  const { mutateAsync: updateMutate } = useMutation(updateCartItemQty);
  const [cartList, setCartList] = useRecoilState(cartListAtom);

  const deleteItem = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    if (!profile) {
      localRemoveProductFromCart(cartList, index, setCartList);
    } else {
      performRemoveProductFromCart(cartList, index, removeFn, setCartList, queryClient);
    }
  };

  const handleUpdateQty = async (qty: number, operation?: 'increment' | 'decrement') => {
    try {
      if (!profile) {
        localUpdateCartItemQuantity(qty, cartList, item, setCartList);
      } else {
        await performUpdateCartItemQty(cartList, qty, item, updateMutate, removeFn, setCartList);
        queryClient.invalidateQueries(QUERY_KEYS.CART);
      }
      prevValueRef.current = qty;
    } catch (err) {
      enqueueSnackbar(`Failed to update ${name} quantity`, { variant: 'error' });
      setValue(
        operation === 'increment'
          ? prevValueRef.current - 1
          : operation === 'decrement'
          ? prevValueRef.current + 1
          : prevValueRef.current
      );
    }
  };

  const debounceUpdateQty = $.debounce(handleUpdateQty, 1000);

  const onChangeQuantity = (newValue: number, operation?: 'increment' | 'decrement') => {
    setValue(newValue);
    debounceUpdateQty(newValue, operation);
  };

  return (
    <CartProductCon>
      <div className="imageCon">{photo ? <img src={photo} alt="productImage" /> : <Logo />}</div>
      <div className="productDetailsCon">
        <div className="top">
          <Tooltip title={name}>
            <h1>{truncateCharCount(name, 12)}</h1>
          </Tooltip>
          <h4>{formatCurrency(amount)}</h4>
        </div>
        <div className="bottom">
          <ProductQtyCounter value={value} setValue={onChangeQuantity} max={quantityAvailable} />
          {deleting ? <CircularProgress size={20} color="inherit" /> : <DeleteCartProduct onClick={deleteItem} />}
        </div>
      </div>
    </CartProductCon>
  );
};
