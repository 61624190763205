import QUERY_KEYS from 'network/config/queryKeys';
import { useCallback } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getProductByCategory, getSubcategories, getVendorById } from '../../network/services/goods';

export default function useGoodsByCategoriesHook() {
  const [searchParams] = useSearchParams();
  const LIMIT = 20;
  const category = searchParams.get('category');

  // Log the category parameter for debugging
  console.debug('Category parameter:', category);

  // Check if category is valid
  if (category === null || category.trim() === '') {
    console.error('Invalid category parameter:', category);
    // Handle the error or set a default value if needed
  }

  const {
    isFetching: categoryProductsFetching,
    data: categoryProductsData,
    hasNextPage: categoryProductsHasNext,
    fetchNextPage: categoryProductsFetchNext,
    error: categoryProductsError
  } = useInfiniteQuery({
    queryKey: [QUERY_KEYS.PRODUCT_BY_CATEGORIES, { categoryId: category }],
    enabled: !!category && category.trim() !== '',
    queryFn: async ({ pageParam = 0 }) => {
      if (!category || category.trim() === '') {
        throw new Error('Category ID is missing or invalid');
      }
      return getProductByCategory({ categoryId: category, page: pageParam, size: LIMIT });
    },
    getNextPageParam: (lastPage, allPages) => (lastPage.length === LIMIT ? allPages.length : undefined)
  });

  const fetchMoreCategoryProducts = useCallback(() => {
    if (categoryProductsHasNext) {
      categoryProductsFetchNext();
    }
  }, [categoryProductsFetchNext, categoryProductsHasNext]);

  const { data: subCategories } = useQuery(['subCategories', { categoryId: category || '1' }], getSubcategories, {
    enabled: !!category && category.trim() !== '',
    onError: error => {
      console.error('Failed to fetch subcategories:', error);
    }
  });

  if (categoryProductsError) {
    console.error('Failed to fetch category products:', categoryProductsError);
  }

  const superMarketSubCategories = subCategories || [];

  return {
    superMarketSubCategories,
    categoryProductsData,
    categoryProductsFetching,
    fetchMoreCategoryProducts
  };
}

export const useGoodsByVendor = () => {
  const [searchParams] = useSearchParams();
  const vendorId = searchParams.get('vendorId');

  // Log the vendorId parameter for debugging
  console.debug('Vendor ID parameter:', vendorId);

  return useQuery(['vendorDetails', vendorId], async () => {
    if (!vendorId) {
      console.error('Vendor ID is missing');
      throw new Error('Vendor ID is missing');
    }
    return getVendorById(parseInt(vendorId, 10));
  });
};
