import { Tooltip } from '@mui/material';
import { AddCart, CartTickIcon, LikeIcon, LogoIcon } from 'assets/Icons';
import { Spacer } from 'components/atoms';
import QUERY_KEYS from 'network/config/queryKeys';
import {
  addProductToCart,
  likeProduct,
  removeProductFromCart,
  unlikeProduct,
  updateCartItemQty
} from 'network/mutations/products';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
//import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { cartListAtom } from 'store/atoms/cartAtom';
import { wishListState } from 'store/atoms/productDetail';
import { profileAtom } from 'store/atoms/profileAtom';
import { Product } from 'TYPES/api.body';
import { removeItemAtIndex } from 'utils/arrayHelpers';
import { formatCurrency } from 'utils/currencyFormater';
import { GoodsCon } from './style';
import {
  localAddProductToCart,
  localRemoveProductFromCart,
  performAddProductToCart,
  performRemoveProductFromCart
} from './utils';

interface IProductCard extends Product {
  setOnSeeGoodsDetails: () => void;
}

export const ProductCard = ({ setOnSeeGoodsDetails, ...rest }: IProductCard) => {
  // const route = useNavigate();
  const queryClient = useQueryClient();
  const { mutateAsync: addMutate } = useMutation({ mutationFn: addProductToCart });
  const { mutateAsync: updateMutate } = useMutation({ mutationFn: updateCartItemQty });
  const { mutateAsync: removeMutate } = useMutation({ mutationFn: removeProductFromCart });

  const {
    productId: id,
    productName: name,
    price: amount,
    description: shortDSC,
    productImage1: img,
    quantityAvailable
  } = rest;

  const profile = useRecoilValue(profileAtom);
  const [cartList, setCartList] = useRecoilState(cartListAtom);
  const itemIndex = cartList.findIndex(item => item.id === id);
  const isItemInCartlist = itemIndex >= 0;
  const [wishList, setWishList] = useRecoilState(wishListState);
  const wishitemIndex = wishList.findIndex(item => item.id === id);
  const isItemInWishlist = wishitemIndex >= 0 || (rest.likes ?? 0) > 0;

  const { mutateAsync: likeProductMutate, isLoading: likeLoading } = useMutation({
    mutationFn: likeProduct,
    onError: err => {
      console.error('Error from likeProduct mutation:', err);
      setWishList(removeItemAtIndex(wishList, wishitemIndex));
    }
  });

  const { mutateAsync: unlikeProductMutate, isLoading: unLikeLoading } = useMutation({
    mutationFn: unlikeProduct,
    onError: error => {
      console.error('Error from unlikeProduct mutation:', error);
      setWishList(c => [
        ...c,
        {
          id,
          amount,
          img,
          name,
          isLiked: false,
          shortDSC,
          imgs: [img],
          atrs: []
        }
      ]);
    }
  });

  const toggleCartItem: React.MouseEventHandler<HTMLButtonElement> = async e => {
    e.stopPropagation();
    const product = { quantity: 1, name, id, photo: img, shortDSC, amount, quantityAvailable };
    if (profile === undefined) {
      localAddProductToCart(1, cartList, product, setCartList);
      if (isItemInCartlist) {
        localRemoveProductFromCart(cartList, itemIndex, setCartList);
      }
      // route('/login'); // i commented it out cause it wasn't given the same behavior as clicking add to cart button in GoodsDetailModal..
      return;
    }
    try {
      if (isItemInCartlist) {
        await performRemoveProductFromCart(cartList, itemIndex, removeMutate, setCartList, queryClient);
      } else {
        await performAddProductToCart(
          cartList,
          [product],
          addMutate,
          updateMutate,
          removeMutate,
          setCartList,
          queryClient
        );
      }
    } catch (error) {
      console.error('Error toggling cart item:', error);
    }
  };

  const toggleLikeProduct: React.MouseEventHandler = async e => {
    e.stopPropagation();
    try {
      if (isItemInWishlist) {
        await unlikeProductMutate(id);
        queryClient.invalidateQueries([QUERY_KEYS.FAVOURITE_PRODUCTS]);
        setWishList(wishList.filter(item => item.id !== id));
      } else {
        await likeProductMutate(id);
        queryClient.invalidateQueries([QUERY_KEYS.FAVOURITE_PRODUCTS]);
        setWishList([
          ...wishList,
          {
            id,
            amount,
            img,
            name,
            isLiked: false,
            shortDSC,
            imgs: [img],
            atrs: []
          }
        ]);
      }
    } catch (error) {
      console.error('Error toggling like product:', error);
    }
  };

  return (
    <GoodsCon $isLiked={isItemInWishlist} onClick={setOnSeeGoodsDetails}>
      <div className="likeCon">
        <button disabled={likeLoading || unLikeLoading} onClick={toggleLikeProduct} type="button">
          <LikeIcon />
        </button>
      </div>
      <div className="imageCon">{img != null ? <img id={name} src={img} alt={name} /> : <LogoIcon />}</div>
      <div className="goodsDetails">
        <div className="info">
          <Tooltip title={name}>
            <h3>{name}</h3>
          </Tooltip>
          <h4>{formatCurrency(amount)}</h4>
        </div>
        <Spacer height={'1.5rem'} />
        <div className="info">
          <p className="line-clamp-1 !text-black !no-underline">{shortDSC?.replace(/<[^>]*>/g, '')}</p>

          <button disabled={quantityAvailable < 1} className="outline-none" onClick={toggleCartItem}>
            {isItemInCartlist ? (
              <CartTickIcon />
            ) : (
              <div className={`addScale ${quantityAvailable < 1 && ' opacity-30'}`}>
                <AddCart />
              </div>
            )}
          </button>
        </div>
      </div>
    </GoodsCon>
  );
};
