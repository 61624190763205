import {
  blob,
  fashion as fashionImg,
  health as healthImg,
  home as homeImg,
  supermarket
} from 'components/molecules/blobs';
import { Link } from 'react-router-dom';
import { Category } from 'TYPES/api.body';

interface Props {
  categories: Category[];
}

const makeSlug = (name: string) => name.toLowerCase().split(' ').join('-');

const BlobGrid: React.FC<Props> = ({ categories }) => {
  const fashion = categories.filter(item => item.name.toLowerCase().includes('fashion')).pop();
  const electronics = categories.filter(item => item.name.toLowerCase().includes('electronic')).pop();
  const home = categories.filter(item => item.name.toLowerCase().includes('home')).pop();
  const health = categories.filter(item => item.name.toLowerCase().includes('health')).pop();
  const superMarket = categories.filter(item => item.name.toLowerCase().includes('super market')).pop();

  return (
    <div className="mb-12">
      <div className="flex justify-between px-3">
        <div className="flex flex-col items-center">
          <Link
            className="flex flex-col items-center"
            to={
              electronics !== undefined
                ? `/categories/supermarket/${makeSlug(electronics.name)}?categoryId=${electronics?.id}`
                : '#'
            }>
            <div className="blobsm blob1 mb-2 grid place-content-center">
              <img src={blob} alt="blob" width={70} height={70} />
            </div>
            <span className="text-white">Electronics</span>
          </Link>
        </div>
        <div className="flex flex-col items-center ">
          <Link
            className="flex flex-col items-center"
            to={
              fashion !== undefined
                ? `/categories/supermarket/${makeSlug(fashion.name)}?categoryId=${fashion?.id}`
                : '#'
            }>
            <div className="blobsm blob2 mb-2 grid place-content-center">
              <img src={fashionImg} alt="blob" width={70} height={70} />
            </div>
            <span className="text-white">Fashion</span>
          </Link>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="flex flex-col items-center">
          <Link
            className="flex flex-col items-center"
            to={
              superMarket !== undefined
                ? `/categories/supermarket/${makeSlug(superMarket.name)}?category=${superMarket?.id}`
                : '#'
            }>
            <div className="blobsm blob3 mb-2 grid place-content-center">
              <img src={supermarket} alt="blob" width={70} height={70} />
            </div>
            <span className="text-white">Supermarket</span>
          </Link>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex flex-col items-center">
          <Link
            className="flex flex-col items-center"
            to={home !== undefined ? `/categories/supermarket/${makeSlug(home.name)}?categoryId=${home?.id}` : '#'}>
            <div className="blobsm blob4 mb-2 grid place-content-center">
              <img src={homeImg} alt="blob" width={70} height={70} />
            </div>
            <span className="text-white">Home</span>
          </Link>
        </div>
        <div className="flex flex-col items-center">
          <Link
            className="flex flex-col items-center"
            to={
              health !== undefined ? `/categories/supermarket/${makeSlug(health.name)}?categoryId=${health?.id}` : '#'
            }>
            <div className="blobsm blob5 mb-2 grid place-content-center">
              <img src={healthImg} alt="blob" width={150} height={150} />
            </div>
            <span className="text-white">Health</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlobGrid;
